const ReviewingMethods = {
    gavelPeerReview: {
        id: 'gavelPeerReview',
        label: 'Gavel, Peer-reviewing',
    },
    manualReview: {
        id: 'manualReview',
        label: 'Manual review',
    },
}

module.exports = ReviewingMethods
